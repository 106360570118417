import React, {useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../pages/Paymentscreen.css';
import Health_component from '../components/health_component';
import Header_packages from '../components/Header_packages';
import { useTranslation } from 'react-i18next';

const PaymentScreen = () => {
  const { t } = useTranslation();
  const trackEvent = (action, category, label) => {
    if (window.gtag) {
        window.gtag('event', action, {
            event_category: category,
            event_label: label,
        });
        // Log to console for debugging
        console.log(`Event tracked: ${action}, Category: ${category}, Label: ${label}`);
    } else {
        // Log a message if gtag is not defined
        console.log('gtag is not defined, event not tracked:', { action, category, label });
    }
};
useEffect(() => {
  trackEvent('page_load', 'user_interaction', 'per_call_package_screen');
 
}, []);
  return (
    <div>
      <Header_packages title={t('call_package')} />
      <Health_component />
    </div>
  );
};

export default PaymentScreen;




// import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import '../pages/Paymentscreen.css';
// import Health_component from '../components/health_component'
// import Header_packages from '../components/Header_packages';

// const PaymentScreen = () => {
//     return (
//      <div>
//       <Header_packages title="Call Pacakge" />
//       <Health_component />
//      </div>
//     );
//   };
  
//   export default PaymentScreen;
