import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import "../../src/style.css";
import "./health_component.css";

const HealthComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const trackEvent = (action, category, label) => {
    if (window.gtag) {
      window.gtag('event', action, {
        event_category: category,
        event_label: label,
      });
      // Log to console for debugging
      console.log(`Event tracked: ${action}, Category: ${category}, Label: ${label}`);
    } else {
      // Log a message if gtag is not defined
      console.log('gtag is not defined, event not tracked:', { action, category, label });
    }
  };
  const handleSubscribe = async () => {
    trackEvent('button_click', 'user_interaction', 'pay_now_btn_click_on_per_package_call_screen');
    Swal.fire({
      title: t("subscribe"),
      text: t("premium_call_feature"),
      icon: "info",
      showCancelButton: true,
      confirmButtonText: t("confirm"),
      cancelButtonText: t("cancel"),
      allowOutsideClick: false,
    }).then(async (result) => {
      trackEvent('alert_pay_now_button_click', 'user_interaction', 'alert_pay_now_button_click_per_call_package');

      if (result.isConfirmed) {
        await onAPIActivation();
      }
    });
  };

  const onAPIActivation = useCallback(async () => {
    const user = JSON.parse(sessionStorage.getItem("userData")) || {};
    const mobileNumber = user.userMsisdn;
    try {
      const response = await fetch(
        "https://bankalfalah.webddocsystems.com/afghan.php/topUp",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            msisdn: mobileNumber,
            priceListTag: "DOC_TOPUP",
            serviceTag: "WEBDOC_DH",
            channel: "CHANNEL_WEB",
          }),
        }
      );
      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(responseData.message);
      }

      if (responseData.code != 0 || responseData.message == "Charging Failed ") {
        Swal.fire({
          icon: "warning",
          // title: t("enterOTP"),
          text: responseData.message,
          confirmButtonText: "OK",
        });
      } else {
        await dbDataActivation();
      }
    } catch (error) {
      console.error("Error resending OTP:", error);
    }
  }, [t]);

  const dbDataActivation = useCallback(async () => {
    try {
      const user = JSON.parse(sessionStorage.getItem("userData")) || {};
      const sessionUserId = user.applicationUserId;
      const response = await axios.post(
        "https://bundleapi.webddocsystems.com/api/Account/ActivateOnetimeConsultation",
        null,
        {
          params: {
            patientId: sessionUserId,
            productid: 2,
          },
        }
      );
      if (response.status === 200 && response.data.responseCode === "0000") {
        user.appointmentId = response.data.appointmentId;
        user.package = "Yes";
        sessionStorage.setItem("userData", JSON.stringify(user));
        Swal.fire({
          // title: t("subscription_success"),
          text: t("subscription_success"),
          icon: "success",
          confirmButtonText: t("confirm"),
          allowOutsideClick: false,
        }).then(() => {
          navigate("/doctors-list");
        });
      } else {
        Swal.fire({
          title: t("error"),
          text: t("subscription_failed"),
          icon: "error",
          confirmButtonText: t("confirm"),
          allowOutsideClick: false,
        });
      }
    } catch (error) {
      console.error("Error activating subscription:", error);
      Swal.fire({
        title: t("error"),
        text: t("error_processing"),
        icon: "error",
        confirmButtonText: t("confirm"),
        allowOutsideClick: false,
      });
    }
  }, [t]);

  return (
    <div style={{ paddingTop: "50px" }}>
      <Row style={{ marginBottom: "100px" }}>
        <Col xs={12} sm={12} md={4} lg={4}>
          <div className="d-flex justify-content-center mt-3">
            <img
              src="https://portal.webdoc.com.pk/doctorprofiles/pngtree-online-doctor-health-service-png-image_10150105 1.png"
              style={{ width: "60%" }}
              alt="Package"
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={8} lg={8} className="mt-4 p-4">
          <div className="m-2 mt-0">
            <p>{t("call_doc")}</p>
            <p dangerouslySetInnerHTML={{ __html: t("subscription_price") }} />
          </div>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} className="text-center">
          <p className="package-price">{t("price_per_call")}</p>
          <p className="ms-5 custom-typography-new">{t("per_call")}</p>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} className="text-center mt-2">
          <Button
            variant="outline-info"
            style={{
              fontWeight: "500",
              backgroundColor: "rgb(113, 158, 25)",
              color: "#ffffff",
              height: "50px",
              minWidth: "40%",
              borderRadius: "40px",
              border: 0,
              boxShadow: "0 0 6.3px 1px rgba(0, 0, 0, 0.25)",
            }}
            type="submit"
            onClick={handleSubscribe}
          >
            {t("pay_now")}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default HealthComponent;

// import React from "react";
// import { useNavigate } from "react-router-dom";
// import { Row, Col, Button } from "react-bootstrap";
// import axios from "axios";
// import Swal from "sweetalert2";
// import "../../src/style.css";
// import "./health_component.css";

// const HealthComponent = () => {
//   const navigate = useNavigate();

//   const handleSubscribe = async () => {
//     Swal.fire({
//       title: "Subscribe",
//       text: "Get premium call to doc feature for just 99 AFN per call.",
//       icon: "info",
//       showCancelButton: true,
//       confirmButtonText: "Confirm",
//       cancelButtonText: "Cancel",
//       allowOutsideClick: false,
//     }).then(async (result) => {
//       if (result.isConfirmed) {
//         try {
//           const user = JSON.parse(sessionStorage.getItem("userData")) || {};
//           const sessionUserId = user.applicationUserId;
//           const response = await axios.post(
//             "https://bundleapi.webddocsystems.com/api/Account/ActivateOnetimeConsultation",
//             null,
//             {
//               params: {
//                 patientId: sessionUserId,
//                 productid: 2,
//               },
//             }
//           );
//           if (
//             response.status === 200 &&
//             response.data.responseCode === "0000"
//           ) {
//             user.package = "Yes";
//             sessionStorage.setItem("userData", JSON.stringify(user));
//             Swal.fire({
//               title: "Subscribed!",
//               text: "You have successfully subscribed to the Call a Doc Package.",
//               icon: "success",
//               confirmButtonText: "OK",
//               allowOutsideClick: false,
//             }).then(() => {
//               navigate("/doctors-list");
//             });
//           } else {
//             Swal.fire({
//               title: "Failed!",
//               text: "Something went wrong, please try again later.",
//               icon: "error",
//               confirmButtonText: "OK",
//               allowOutsideClick: false,
//             });
//           }
//         } catch (error) {
//           console.error("Error activating subscription:", error);
//           Swal.fire({
//             title: "Error!",
//             text: "An error occurred while processing your request. Please try again later.",
//             icon: "error",
//             confirmButtonText: "OK",
//             allowOutsideClick: false,
//           });
//         }
//       }
//     });
//   };

//   return (
//     <div style={{ paddingTop: "50px" }}>
//       <Row style={{ marginBottom: "100px" }}>
//         <Col xs={12} sm={12} md={4} lg={4}>
//           <div className="d-flex justify-content-center mt-3">
//             <img
//               src="https://portal.webdoc.com.pk/doctorprofiles/pngtree-online-doctor-health-service-png-image_10150105 1.png"
//               style={{ width: "60%" }}
//               alt="Package"
//             />
//           </div>
//         </Col>
//         <Col xs={12} sm={12} md={8} lg={8} className="mt-4 p-4">
//           <div className="m-2 mt-0">
//             <p>
//               Connect to a PMDC registered doctor anywhere, anytime. Consult the
//               doctor 9am to 9pm anytime for a routine medical checkup and keep a
//               check on your health.
//             </p>
//             <p>
//               Subscribe for <strong>just 99 AFN</strong> and gain access to
//               verified doctors for real-time consultations. Get expert medical
//               advice from trusted professionals whenever you need it.
//             </p>
//           </div>
//         </Col>
//         <Col xs={12} sm={12} md={6} lg={6} className="text-center">
//           <p className="package-price">99 AFN /</p>
//           <p className="ms-5 custom-typography-new">Per Call</p>
//         </Col>
//         <Col xs={12} sm={12} md={6} lg={6} className="text-center mt-2">
//           <Button
//             variant="outline-info"
//             style={{
//               fontWeight: "500",
//               backgroundColor: "rgb(113, 158, 25)",
//               color: "#ffffff",
//               height: "50px",
//               minWidth: "40%",
//               borderRadius: "40px",
//               border: 0,
//               boxShadow: "0 0 6.3px 1px rgba(0, 0, 0, 0.25)",
//             }}
//             type="submit"
//             onClick={handleSubscribe}
//           >
//             Pay Now
//           </Button>
//         </Col>
//       </Row>
//     </div>
//   );
// };

// export default HealthComponent;
