import React from "react";
import { useTranslation } from "react-i18next"; // Import useTranslation
import "bootstrap/dist/css/bootstrap.min.css"; // Ensure Bootstrap is imported

const FrameComponent1 = ({ data }) => {
  const { t } = useTranslation(); // Initialize useTranslation

  const averageRating =
    data && typeof data.averageRating === "number"
      ? data.averageRating.toFixed(1)
      : "";

  return (
    <div className="d-flex flex-row align-items-start bg-light border border-light shadow-sm p-4">
      <div className="flex-grow-1 d-flex flex-column align-items-center">
        <div className=" text-dark">{t("experienceLabel")}</div>
        <div className="text-muted custom-text-class">{data ? data.experience : ""}</div>
      </div>
      <div className="vr mx-3" /> {/* Vertical line using Bootstrap */}
      <div className="flex-grow-1 d-flex flex-column align-items-center">
        <div className=" text-dark">{t("ratingsLabel")}</div>
        <div className="custom-text-class">{averageRating}</div>
      </div>
      <div className="vr mx-3" /> {/* Vertical line using Bootstrap */}
      <div className="flex-grow-1 d-flex flex-column align-items-center">
        <div className=" text-dark">{t("qualificationsLabel")}</div>
        <div className="text-muted custom-text-class">{data ? data.allqualifications : ""}</div>
      </div>
    </div>
  );
};

export default FrameComponent1;
