
import React from 'react';
import Header_packages from '../components/Header_packages';
import DoctorListComponent from '../components/doctor-list-component';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const DoctorsList = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header_packages title={t('doctors')} />
      <Container className="mt-5 pt-3">
        <DoctorListComponent />
      </Container>
    </>
  );
};

export default DoctorsList;



// import Header_packages from "../components/Header_packages";
// import DoctorListComponent from "../components/doctor-list-component";
// import { Container } from "react-bootstrap";

// const DoctorsList = () => (
//   <>
//  <Header_packages title="Doctors"/>
//     <Container className="mt-5 pt-3">
//       <DoctorListComponent />
//     </Container>
//   </>
// );

// export default DoctorsList;
