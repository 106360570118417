import React from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import "./HealthTips.css"; // Custom styles for the HealthTips component

const HealthTips = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const session = JSON.parse(sessionStorage.getItem("userData"));
 
  const checkFeaturesPackageSubscription = (callback) => {
    if (!session?.featuresPackage) {
      Swal.fire({
        icon: "info",
        title: t("package_subscription"),
        text: t("no_subscription_text"),
        showCancelButton: true,
        confirmButtonText: t("pay_now"),
        cancelButtonText: t("cancel"),
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate(`/feature-package`);
        }
      });
    } else {
      callback();
    }
  };

  const handleLinkClick = (path) => {
    checkFeaturesPackageSubscription(() => {
      navigate(`/HealthTips/index?category=${path}`);
    });
  };

  const categories = [
    {
      name: t("diabetes"),
      path: "diabetes",
      img: "assets/NewMiniApp/Diabetes.png",
    },
    {
      name: t("pelvic_inflammatory"),
      path: "Pelvic_Inflammatory",
      img: "assets/NewMiniApp/Pelvic.png",
    },
    {
      name: t("tuberculosis"),
      path: "tuberculosis",
      img: "assets/NewMiniApp/TB.png",
    },
    {
      name: t("gastroenteritis"),
      path: "gastroenteritis",
      img: "assets/NewMiniApp/Gastro.png",
    },
  ];

  return (
    <>
      <div className="row mb-2">
        <div className="col-12">
          <h6 className="health-tips-title">{t("health_tips")}</h6>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div
            className="row flex-nowrap health-tips-carousel"
            style={{ overflowX: "auto", gap: "5px" }}
          >
            {categories.map((category, index) => (
              <div
                className="col-5 mb-4 healthtips"
                key={index}
                onClick={() => handleLinkClick(category.path)}
              >
                <img
                  src={category.img}
                  className="d-block healthtips-image"
                  alt={category.name}
                  style={{ height: "150px", objectFit: "cover" }}
                />
                <p className="healthtips-text">{category.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default HealthTips;

// import React from "react";
// import { useNavigate } from "react-router-dom";
// import Swal from "sweetalert2";
// import "./HealthTips.css"; // Custom styles for the HealthTips component

// const HealthTips = () => {
//   const navigate = useNavigate();
//   const session = JSON.parse(sessionStorage.getItem("userData"));

//   const checkFeaturesPackageSubscription = (callback) => {
//     if (!session?.featuresPackage) {
//       Swal.fire({
//         icon: "info",
//         title: "Package Subscription",
//         text: "You don't have an active subscription. Please subscribe to a feature package for just 5 AFN per day to access premium features.",
//         showCancelButton: true,
//         confirmButtonText: "Pay Now",
//         cancelButtonText: "Cancel",
//         allowOutsideClick: false,
//       }).then((result) => {
//         if (result.isConfirmed) {
//           navigate(`/feature-package`);
//         }
//       });
//     } else {
//       callback();
//     }
//   };

//   const handleLinkClick = (path) => {
//     checkFeaturesPackageSubscription(() => {
//       navigate(`/HealthTips/index?category=${path}`);
//     });
//   };

//   const categories = [
//     {
//       name: "Diabetes",
//       path: "diabetes",
//       img: "assets/NewMiniApp/Diabetes.png",
//     },
//     {
//       name: "Pelvic Inflammatory",
//       path: "Pelvic_Inflammatory",
//       img: "assets/NewMiniApp/Pelvic.png",
//     },
//     {
//       name: "Tuberculosis",
//       path: "tuberculosis",
//       img: "assets/NewMiniApp/TB.png",
//     },
//     {
//       name: "Gastroenteritis",
//       path: "gastroenteritis",
//       img: "assets/NewMiniApp/Gastro.png",
//     },
//   ];

//   return (
//     <>
//       <div className="row mb-2">
//         <div className="col-12">
//           <h6 className="health-tips-title">Health Tips</h6>
//         </div>
//       </div>
//       <div className="row">
//         <div className="col-12">
//           <div
//             className="row flex-nowrap health-tips-carousel"
//             style={{ overflowX: "auto", gap: "5px" }}
//           >
//             {categories.map((category, index) => (
//               <div
//                 className="col-5 mb-4 healthtips"
//                 key={index}
//                 onClick={() => handleLinkClick(category.path)}
//               >
//                 <img
//                   src={category.img}
//                   className="d-block healthtips-image"
//                   alt={category.name}
//                   style={{ height: "150px", objectFit: "cover" }}
//                 />
//                 <p className="healthtips-text">{category.name}</p>
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default HealthTips;
