import React, { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Home from "./pages/Home";
import DoctorsList from "./pages/doctors-list";
import VideoCall from "./pages/video-call";
import PrescriptionsList from "./pages/prescriptions-list";
import Prescription from "./pages/prescription";
import Swal from "sweetalert2";
import "./App.css";
import PaymentScreen from "./pages/Paymentscreen";
import { LoadingProvider } from "./contexts/LoadingContext";
import Loader from "./components/loader";
import HealthTipsDetail from "./components/HealthTipsDetail";
import DoctorProfile from "./pages/doctor-profile";
import VitalsChart from "./pages/VitalsChart";
import AddVitals from "./pages/AddVitals";
import FeaturePackagePage from "./pages/FeaturePackage";
import SignUpForm from "./pages/SignUpForm";
import MobileOTP from "./pages/MobileOTP";
import ProfilePage from "./pages/ProfilePage";
import ProfileEditPage from "./pages/ProfileEditPage";
import PaymentOTP from "./pages/PaymentOTP";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  // const ActivePackageFilter = ({ children }) => {
  //   const session = JSON.parse(sessionStorage.getItem("userData"));
  //   const navigate = useNavigate();

  //   useEffect(() => {
  //     if (!session?.package) {
  //       Swal.fire({
  //         icon: "info",
  //         title: "Package Subscription",
  //         text: "Sorry, you do not have an active subscription. Please subscribe to a package to access.",
  //         showCancelButton: true,
  //         confirmButtonText: "Buy Package",
  //         cancelButtonText: "Back",
  //       }).then((result) => {
  //         if (result.isConfirmed) {
  //           navigate("/paymentscreen");
  //         } else if (result.dismiss === Swal.DismissReason.cancel) {
  //           navigate(-1);
  //         }
  //       });
  //     }
  //   }, [session, navigate]);

  //   return session && session.package ? children : null;
  // };

  // const FeaturesActivePackageFilter = ({ children }) => {
  //   const session = JSON.parse(sessionStorage.getItem("userData"));
  //   const navigate = useNavigate();

  //   useEffect(() => {
  //     if (!session?.featuresPackage) {
  //       Swal.fire({
  //         icon: "info",
  //         title: "Package Subscription",
  //         text: "Sorry, you do not have an active subscription. Please subscribe to a features package to access.",
  //         showCancelButton: true,
  //         confirmButtonText: "Buy Package",
  //         cancelButtonText: "Back",
  //       }).then((result) => {
  //         if (result.isConfirmed) {
  //           navigate(`/dashboard`);
  //         } else if (result.dismiss === Swal.DismissReason.cancel) {
  //           navigate(-1);
  //         }
  //       });
  //     }
  //   }, [session, navigate]);

  //   return session && session.featuresPackage ? children : null;
  // };

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "Login";
        metaDescription = "";
        break;
      case "/dashboard":
        title = "Home page";
        metaDescription = "This is home page.";
        break;
      case "/doctors-list":
        title = "Doctor List";
        metaDescription = "";
        break;
      case "/prescriptions-list":
        title = "Prescription List";
        metaDescription = "";
        break;
      case "/paymentscreen":
        title = "Payment";
        metaDescription = "";
        break;
      case "/prescriptions":
        title = "Prescriptions";
        metaDescription = "";
        break;
      case "/video-call":
        title = "Video Call";
        metaDescription = "";
        break;
      case "/prescription/:id":
        title = "Prescription details";
        metaDescription = "";
        break;
      case "/HealthTips/index":
        title = "Health Tips";
        metaDescription = "";
        break;
      case "/doctor-profile/:id":
        title = "Doctor Profile";
        metaDescription = "";
        break;
      case "/add-vitals":
        title = "Add Vitals";
        metaDescription = "";
        break;
      case "vitals":
        title = "Patient Vitals";
        metaDescription = "";
        break;
      case "/feature-package":
        title = "Feature Package";
        metaDescription = "";
        break;
      case "/otp":
        title = "OTP Screen";
        metaDescription = "";
        break;
      case "/payment-otp":
        title = "Payment OTP";
        metaDescription = "";
        break;
      case "/profile":
        title = "Profile";
        metaDescription = "";
        break;
      case "/profile-edit":
        title = "Edit Profile";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <LoadingProvider>
      <Loader />
      <Routes>
        <Route path="/" element={<SignUpForm />} />
        <Route path="/dashboard" element={<Home />} />
        <Route path="/doctors-list" element={<DoctorsList />} />
        <Route path="/video-call" element={<VideoCall />} />
        <Route path="/paymentscreen" element={<PaymentScreen />} />
        <Route path="/prescriptions-list" element={<PrescriptionsList />} />
        <Route path="/prescription/:id" element={<Prescription />} />
        <Route path="/HealthTips/index" element={<HealthTipsDetail />} />
        <Route path="/doctor-profile/:id" element={<DoctorProfile />} />
        <Route path="/add-vitals" element={<AddVitals />} />
        <Route path="/vitals" element={<VitalsChart />} />
        <Route path="/feature-package" element={<FeaturePackagePage />} />
        <Route path="/otp" element={<MobileOTP />} />
        <Route path="/payment-otp" element={<PaymentOTP />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/profile-edit" element={<ProfileEditPage />} />
      </Routes>
    </LoadingProvider>
  );
}

export default App;
