import React, { useState, useEffect } from "react";
import axios from "axios";
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from "recharts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useLoading } from "../contexts/LoadingContext";
import Header_packages from "../components/Header_packages";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next"; 
import "../i18n"; 

const VitalsChart = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [vitalsData, setVitalsData] = useState([]);
  const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 3)));
  const [endDate, setEndDate] = useState(new Date());
  const { isLoading, setIsLoading } = useLoading();
  const session = JSON.parse(sessionStorage.getItem("userData"));
  const sessionUserId = session?.applicationUserId || null;

  const trackEvent = (action, category, label) => {
    if (window.gtag) {
        window.gtag('event', action, {
            event_category: category,
            event_label: label,
        });
        // Log to console for debugging
        console.log(`Event tracked: ${action}, Category: ${category}, Label: ${label}`);
    } else {
        // Log a message if gtag is not defined
        console.log('gtag is not defined, event not tracked:', { action, category, label });
    }
};

useEffect(() => {
  trackEvent('page_load', 'user_interaction', 'Vitals_Chart_screen');
 
}, []);
  useEffect(() => {
    if (sessionUserId) {
      fetchVitalsData(sessionUserId, startDate, endDate);
    }
  }, [sessionUserId, startDate, endDate]);

  const fetchVitalsData = async (patientId, startDate, endDate) => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        "https://bundleapi.webddocsystems.com/api/Account/Getvitals",
        null,
        {
          params: { patientid: patientId },
        }
      );
      if (response.status === 200 && response.data.responseCode === "0000") {
        const filteredVitals = response.data.vitals.filter((vital) => {
          const vitalDate = new Date(vital.datetime);
          return vitalDate >= startDate && vitalDate <= endDate;
        });
        setVitalsData(filteredVitals);
      } else {
        console.error("Invalid response format or responseCode");
      }
    } catch (error) {
      console.error("Error fetching vitals:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const parseDate = (dateString) => {
    return new Date(dateString).toLocaleDateString("en-US");
  };

  const prepareChartData = (vitals) => {
    return vitals.map((vital) => ({
      date: parseDate(vital.datetime),
      bloodPressure: parseFloat(vital.bloodPressure.split("/")[0]),
      bloodSugar: parseFloat(vital.bloodSugar) || 0,
      oxygenSaturation: parseFloat(vital.oxygenSaturation) || 0,
      pulseRate: parseFloat(vital.pulseRate) || 0,
      respiratoryRate: parseFloat(vital.respiratoryRate) || 0,
      weight: parseFloat(vital.weight) || 0,
      height: parseFloat(vital.height) || 0,
    }));
  };

  return (
    <Container fluid>
      <Header_packages title={t("patientVitalsTitle")} />
      <div style={{ paddingTop: "130px" }}>
        <Row className="mt-4 p-2">
          <Col>
            <div className="d-flex justify-content-between">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat={t("dateFormat")}
                className="form-control"
              />
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                dateFormat={t("dateFormat")}
                className="form-control"
              />
            </div>
            <Button
              style={{
                position: "absolute",
                top: 100,
                right: 20,
                padding: "10px 20px",
                backgroundColor: "#007bff",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                fontSize: "16px",
              }}
              onClick={() => navigate("/add-vitals")}
            >
              {t("addVitals")}
            </Button>
          </Col>
        </Row>

        <Row className="mt-4 p-2">
          <Col>
            {isLoading ? (
              <div>{t("loading")}</div>
            ) : (
              <ResponsiveContainer width="100%" height={400} style={{marginLeft:"-20px"}}>
                <LineChart data={prepareChartData(vitalsData)}>
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="bloodPressure" stroke="#FF6384" strokeWidth={2} />
                  <Line type="monotone" dataKey="bloodSugar" stroke="#36A2EB" strokeWidth={2} />
                  <Line type="monotone" dataKey="oxygenSaturation" stroke="#4BC0C0" strokeWidth={2} />
                  <Line type="monotone" dataKey="pulseRate" stroke="#9966FF" strokeWidth={2} />
                  <Line type="monotone" dataKey="respiratoryRate" stroke="#FFCE56" strokeWidth={2} />
                  <Line type="monotone" dataKey="weight" stroke="#FF9F40" strokeWidth={2} />
                  <Line type="monotone" dataKey="height" stroke="#36A2EB" strokeWidth={2} />
                </LineChart>
              </ResponsiveContainer>
            )}
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default VitalsChart;




// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { Line } from "react-chartjs-2";
// import {
//   Chart as ChartJS,
//   LineElement,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   Tooltip,
//   Legend,
//   Title,
// } from "chart.js";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import { Container, Row, Col, Button } from "react-bootstrap";
// import { useLoading } from "../contexts/LoadingContext";
// import Header_packages from "../components/Header_packages";
// import { useNavigate } from "react-router-dom";
// import { useTranslation } from "react-i18next"; // Import useTranslation
// import "../i18n"; // Import i18n configuration

// // Register Chart.js components
// ChartJS.register(
//   LineElement,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   Tooltip,
//   Legend,
//   Title
// );

// const VitalsChart = () => {
//   const { t } = useTranslation(); // Initialize useTranslation
//   const navigate = useNavigate();
//   const [vitalsData, setVitalsData] = useState([]);
//   const [startDate, setStartDate] = useState(
//     new Date(new Date().setDate(new Date().getDate() - 3))
//   );
//   const [endDate, setEndDate] = useState(new Date());
//   const { isLoading, setIsLoading } = useLoading();
//   const session = JSON.parse(sessionStorage.getItem("userData"));
//   const sessionUserId = session?.applicationUserId || null;

//   useEffect(() => {
//     if (sessionUserId) {
//       fetchVitalsData(sessionUserId, startDate, endDate);
//     }
//   }, [sessionUserId, startDate, endDate]);

//   const fetchVitalsData = async (patientId, startDate, endDate) => {
//     setIsLoading(true);
//     try {
//       const response = await axios.post(
//         "https://bundleapi.webddocsystems.com/api/Account/Getvitals",
//         null,
//         {
//           params: { patientid: patientId },
//         }
//       );
//       if (response.status === 200 && response.data.responseCode === "0000") {
//         const filteredVitals = response.data.vitals.filter((vital) => {
//           const vitalDate = new Date(vital.datetime);
//           return vitalDate >= startDate && vitalDate <= endDate;
//         });
//         setVitalsData(filteredVitals);
//       } else {
//         console.error("Invalid response format or responseCode");
//       }
//     } catch (error) {
//       console.error("Error fetching vitals:", error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const parseDate = (dateString) => {
//     const options = {
//       year: "numeric",
//       month: "short",
//       day: "numeric",
//       hour: "numeric",
//       minute: "numeric",
//       hour12: true,
//     };
//     return new Date(dateString).toLocaleDateString("en-US", options);
//   };

//   const parseNumericValue = (value) => {
//     return parseFloat(value) || 0;
//   };

//   const prepareChartData = (vitals) => {
//     const labels = vitals.map((vital) => parseDate(vital.datetime));
//     return {
//       labels,
//       datasets: [
//         {
//           label: "Blood Pressure",
//           data: vitals.map((vital) =>
//             parseNumericValue(vital.bloodPressure.split("/")[0])
//           ),
//           borderColor: "rgba(255, 99, 132, 1)",
//           backgroundColor: "rgba(255, 99, 132, 0.2)",
//           borderWidth: 2,
//           pointRadius: 5,
//           pointBackgroundColor: "rgba(255, 99, 132, 1)",
//           pointBorderColor: "#fff",
//           tension: 0.4,
//         },
//         {
//           label: "Blood Sugar",
//           data: vitals.map((vital) => parseNumericValue(vital.bloodSugar)),
//           borderColor: "rgba(54, 162, 235, 1)",
//           backgroundColor: "rgba(54, 162, 235, 0.2)",
//           borderWidth: 2,
//           pointRadius: 5,
//           pointBackgroundColor: "rgba(54, 162, 235, 1)",
//           pointBorderColor: "#fff",
//           tension: 0.4,
//         },
//         {
//           label: "Oxygen Saturation",
//           data: vitals.map((vital) =>
//             parseNumericValue(vital.oxygenSaturation)
//           ),
//           borderColor: "rgba(75, 192, 192, 1)",
//           backgroundColor: "rgba(75, 192, 192, 0.2)",
//           borderWidth: 2,
//           pointRadius: 5,
//           pointBackgroundColor: "rgba(75, 192, 192, 1)",
//           pointBorderColor: "#fff",
//           tension: 0.4,
//         },
//         {
//           label: "Pulse Rate",
//           data: vitals.map((vital) => parseNumericValue(vital.pulseRate)),
//           borderColor: "rgba(153, 102, 255, 1)",
//           backgroundColor: "rgba(153, 102, 255, 0.2)",
//           borderWidth: 2,
//           pointRadius: 5,
//           pointBackgroundColor: "rgba(153, 102, 255, 1)",
//           pointBorderColor: "#fff",
//           tension: 0.4,
//         },
//         {
//           label: "Respiratory Rate",
//           data: vitals.map((vital) => parseNumericValue(vital.respiratoryRate)),
//           borderColor: "rgba(255, 159, 64, 1)",
//           backgroundColor: "rgba(255, 159, 64, 0.2)",
//           borderWidth: 2,
//           pointRadius: 5,
//           pointBackgroundColor: "rgba(255, 159, 64, 1)",
//           pointBorderColor: "#fff",
//           tension: 0.4,
//         },
//         {
//           label: "Weight",
//           data: vitals.map((vital) => parseNumericValue(vital.weight)),
//           borderColor: "rgba(255, 206, 86, 1)",
//           backgroundColor: "rgba(255, 206, 86, 0.2)",
//           borderWidth: 2,
//           pointRadius: 5,
//           pointBackgroundColor: "rgba(255, 206, 86, 1)",
//           pointBorderColor: "#fff",
//           tension: 0.4,
//         },
//         {
//           label: "Height",
//           data: vitals.map((vital) => parseNumericValue(vital.height)),
//           borderColor: "rgba(75, 192, 192, 1)",
//           backgroundColor: "rgba(75, 192, 192, 0.2)",
//           borderWidth: 2,
//           pointRadius: 5,
//           pointBackgroundColor: "rgba(75, 192, 192, 1)",
//           pointBorderColor: "#fff",
//           tension: 0.4,
//         },
//       ],
//     };
//   };

//   return (
//     <Container fluid>
//       <Header_packages title={t("patientVitalsTitle")} />
//       <div style={{ paddingTop: "130px" }}>
//         <Row className="mt-4 p-2">
//           <Col>
//             <div className="d-flex justify-content-between">
//               <DatePicker
//                 selected={startDate}
//                 onChange={(date) => setStartDate(date)}
//                 selectsStart
//                 startDate={startDate}
//                 endDate={endDate}
//                 dateFormat={t("dateFormat")}
//                 className="form-control"
//               />
//               <DatePicker
//                 selected={endDate}
//                 onChange={(date) => setEndDate(date)}
//                 selectsEnd
//                 startDate={startDate}
//                 endDate={endDate}
//                 minDate={startDate}
//                 dateFormat={t("dateFormat")}
//                 className="form-control"
//               />
//             </div>
//             <Button
//               style={{
//                 position: "absolute",
//                 top: 100,
//                 right: 20,
//                 padding: "10px 20px",
//                 backgroundColor: "#007bff",
//                 color: "#fff",
//                 border: "none",
//                 borderRadius: "5px",
//                 cursor: "pointer",
//                 fontSize: "16px",
//               }}
//               onClick={() => navigate("/add-vitals")}
//             >
//               {t("addVitals")}
//             </Button>
//           </Col>
//         </Row>

//         <Row className="mt-4 p-2">
//           <Col>
//             {isLoading ? (
//               <div>{t("loading")}</div>
//             ) : (
              
//               <Line
//                 data={prepareChartData(vitalsData)}
//                 options={{
//                   responsive: true,
//                   plugins: {
//                     legend: {
//                       position: "top",
//                       labels: {
//                         font: {
//                           size: 14,
//                           family: "Arial, sans-serif",
//                         },
//                         color: "#333",
//                       },
//                     },
//                     tooltip: {
//                       backgroundColor: "rgba(0, 0, 0, 0.7)",
//                       titleColor: "#fff",
//                       bodyColor: "#fff",
//                       callbacks: {
//                         label: function (tooltipItem) {
//                           return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`;
//                         },
//                       },
//                     },
//                   },
//                   scales: {
//                     x: {
//                       grid: {
//                         display: false,
//                       },
//                       ticks: {
//                         autoSkip: true,
//                         maxTicksLimit: 10,
//                         color: "#666",
//                         font: {
//                           size: 12,
//                         },
//                       },
//                     },
//                     y: {
//                       beginAtZero: true,
//                       grid: {
//                         color: "rgba(0, 0, 0, 0.1)",
//                       },
//                       ticks: {
//                         color: "#666",
//                         font: {
//                           size: 12,
//                         },
//                       },
//                     },
//                   },
//                 }}
//               />
//             )}
//           </Col>
//         </Row>
//       </div>
//     </Container>
//   );
// };

// export default VitalsChart;