// src/components/Header.js
import React from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa"; // You need to install react-icons if you haven't

const Header_packages = ({ title }) => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); // This will navigate to the previous page in the history
  };

  return (
    <div className="row d-flex align-items-center p-3 bg-light border-bottom fixed-top shadow-sm">
      <div className="col-2 d-flex align-items-center">
        <button className="btn btn-link" onClick={handleBackClick}>
          <FaArrowLeft size={15} />
        </button>
      </div>
      <div className="col-8 d-flex justify-content-center">
        <h2 className="mb-0">{title}</h2>
      </div>
    </div>
  );
};

export default Header_packages;
