import React, { useState, useEffect } from "react";
import { Container, Modal, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import Header_packages from "../components/Header_packages";
import { useTranslation } from "react-i18next";
import "bootstrap/dist/css/bootstrap.min.css";
import "./FeaturePackage.css";



const FeaturePackagePage = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [isTermsAccepted, setIsTermsAccepted] = useState(true);
  const [isRecurring, setIsRecurring] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [loadingMore, setResend] = useState(false);

  const isRTL = i18n.dir() === "rtl";

  const trackEvent = (action, category, label) => {
    if (window.gtag) {
        window.gtag('event', action, {
            event_category: category,
            event_label: label,
        });
        // Log to console for debugging
        console.log(`Event tracked: ${action}, Category: ${category}, Label: ${label}`);
    } else {
        // Log a message if gtag is not defined
        console.log('gtag is not defined, event not tracked:', { action, category, label });
    }
};

useEffect(() => {
  trackEvent('page_load', 'user_interaction', 'subscribe_to_feature_package_screen');
 
}, []);

  const handleTermsChange = (e) => {
    setIsTermsAccepted(e.target.checked);
  };

  const handleRecurringChange = (e) => {
    setIsRecurring(e.target.checked);
  };

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", options);
  };

  const handleSubscribe = async () => {
    trackEvent('button_click', 'user_interaction', 'Subscribe-btn');
    const user = JSON.parse(sessionStorage.getItem("userData")) || {};
    const mobileNumber = user.userMsisdn;
    try {
      setResend(true);
      const response = await fetch(
        "https://bankalfalah.webddocsystems.com/afghan.php/sendOtp",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            msisdn: mobileNumber,
            priceListTag: "d",
            serviceTag: "WEBDOC_DH",
            autoRenew: isRecurring,
          }),
        }
      );
      console.log(response);
      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(responseData.message);
      }
      if (responseData.code != 0) {
        if (responseData.message === "Subscription Already Exist") {
          await handleSubscribeFeature();
        } else {
          Swal.fire({
            icon: "warning",
            // title: t("enterOTP"),
            text: responseData.message,
            confirmButtonText: "OK",
          });
        }
      } else {
        navigate("/payment-otp");
      }
    } catch (error) {
      console.error("Error resending OTP:", error);
    } finally {
      setResend(false);
    }
  };

  const handleSubscribeFeature = async () => {
    try {
      const user = JSON.parse(sessionStorage.getItem("userData")) || {};
      const sessionUserId = user.applicationUserId;

      const response = await axios.post(
        "https://bundleapi.webddocsystems.com/api/Account/ActivateFeatureSusbcription",
        null,
        {
          params: {
            patientId: sessionUserId,
            productid: 1,
          },
        }
      );

      if (response.status === 200 && response.data.responseCode === "0000") {
        const currentDate = new Date();
        const expiryDate = new Date();
        expiryDate.setDate(expiryDate.getDate() + 1);
        user.featuresPackage = true;
        user.featurePackageDetails.activeDate = formatDate(
          currentDate.toISOString()
        );
        user.featurePackageDetails.nextChargeDare = formatDate(
          expiryDate.toISOString()
        );
        sessionStorage.setItem("userData", JSON.stringify(user));
        navigate(`/dashboard`);
      } else {
        Swal.fire({
          title: t("subscription_failed"),
          text: t("something_went_wrong"),
          icon: "error",
          confirmButtonText: "OK",
          allowOutsideClick: false,
        });
      }
    } catch (error) {
      console.error("Error activating subscription:", error);
    }
  };

  // const handleSubscribe = async () => {
  //   try {
  //     const user = JSON.parse(sessionStorage.getItem("userData")) || {};
  //     const sessionUserId = user.applicationUserId;

  //     const response = await axios.post(
  //       "https://bundleapi.webddocsystems.com/api/Account/ActivateFeatureSusbcription",
  //       null,
  //       {
  //         params: {
  //           patientId: sessionUserId,
  //           productid: 1,
  //         },
  //       }
  //     );

  //     if (response.status === 200 && response.data.responseCode === "0000") {
  //       const currentDate = new Date();
  //       const expiryDate = new Date();
  //       expiryDate.setDate(expiryDate.getDate() + 1);
  //       user.featuresPackage = true;
  //       user.featurePackageDetails.activeDate = formatDate(
  //         currentDate.toISOString()
  //       );
  //       user.featurePackageDetails.nextChargeDate = formatDate(
  //         expiryDate.toISOString()
  //       );
  //       sessionStorage.setItem("userData", JSON.stringify(user));

  //       Swal.fire({
  //         title: t("subscribe_now"),
  //         text: t("subscription_success"),
  //         icon: "success",
  //         confirmButtonText: "OK",
  //         allowOutsideClick: false,
  //       }).then(() => {
  //         navigate(`/dashboard`);
  //       });
  //     } else {
  //       Swal.fire({
  //         title: t("subscription_failed"),
  //         text: t("something_went_wrong"),
  //         icon: "error",
  //         confirmButtonText: "OK",
  //         allowOutsideClick: false,
  //       });
  //     }
  //   } catch (error) {
  //     console.error("Error activating subscription:", error);
  //   }
  // };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <>
      <Header_packages title={t("terms_conditions")} />
      <Container fluid style={{ direction: isRTL ? "rtl" : "ltr" }}>
        <div style={{ paddingTop: "100px" }}></div>
        <div
          className={`card p-4 shadow-lg rounded-lg ${isRTL ? "text-end" : ""}`}
        >
          <h3 className="card-title text-center mb-4">{t("subscribe_for")}</h3>
          <p className="card-text text-left mb-4">{t("premium_features")}</p>
          <div className="text-left mb-4">
            <p className="card-text">{t("see_medical_history")}</p>
            <p className="card-text">{t("view_doctor_profiles")}</p>
            <p className="card-text">{t("add_vitals")}</p>
            <p className="card-text">{t("get_health_tips")}</p>
          </div>
          <Form>
            <Form.Group className="mb-3">
              <Form.Check
                type="checkbox"
                label={t("recurring_payments")}
                checked={isRecurring}
                onChange={handleRecurringChange}
                className={isRTL ? "text-end" : ""}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Check
                type="checkbox"
                label={
                  <span>
                    {t("terms_and_conditions")}&nbsp;
                    <a
                      href="#"
                      onClick={handleShowModal}
                      className="text-primary"
                    >
                      {t("terms_conditions")}
                    </a>
                  </span>
                }
                checked={isTermsAccepted}
                onChange={handleTermsChange}
                className={isRTL ? "text-end" : ""}
              />
            </Form.Group>

            <Button
              variant="outline-info"
              style={{
                fontWeight: "500",
                color: "#ffffff",
                backgroundColor: "#719E19",
                borderRadius: "40px",
                border: 0,
                boxShadow: "0 0 6.3px 1px rgba(0, 0, 0, 0.25)",
              }}
              disabled={!isTermsAccepted}
              onClick={handleSubscribe}
              className="w-100"
            >
              {t("subscribe")}
            </Button>
          </Form>
        </div>

        {/* Terms and Conditions Modal */}
        <Modal show={showModal} onHide={handleCloseModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>{t("terms_conditions")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <strong>{t("policy_terms")}</strong>
            </p>
            <ul className={isRTL ? "text-end" : ""}>
              <li>
                <p>{t("auto_renewal")}</p>
              </li>
              <li>
                <p>{t("pre_existing_conditions")}</p>
              </li>
              <li>
                <p>{t("cosmetic_surgical")}</p>
              </li>
              <li>
                <p>{t("regular_medicine_si")}</p>
              </li>
              <li>
                <p>{t("mmc_dd_cover")}</p>
              </li>
              <li>
                <p>{t("cover_restriction")}</p>
              </li>
              <li>
                <p>{t("daycare_surgeries")}</p>
              </li>
              <li>
                <p>{t("no_maternity_cover")}</p>
              </li>
              <li>
                <p>{t("policy_reversal")}</p>
              </li>
            </ul>
            <p>
              <strong>{t("doctors_timing")}</strong>
            </p>
            <p>{t("doctors_timing_details")}</p>
            <p>
              <strong>{t("claims_payment")}</strong>
            </p>
            <ul className={isRTL ? "text-end" : ""}>
              <li>
                <p>{t("claims_tat")}</p>
              </li>
            </ul>
            <p>
              <strong>{t("required_documents")}</strong>
            </p>
            <ol className={isRTL ? "text-end" : ""}>
              <li>
                <p>{t("cnic_copy")}</p>
              </li>
              <li>
                <p>{t("treatment_details")}</p>
              </li>
              <li>
                <p>{t("admission_discharge")}</p>
              </li>
              <li>
                <p>{t("payment_receipt")}</p>
              </li>
              <li>
                <p>{t("lab_reports")}</p>
              </li>
              <li>
                <p>{t("msisdn_easypaisa")}</p>
              </li>
            </ol>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              {t("close")}
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
};

export default FeaturePackagePage;

// import React, { useState, useEffect, useCallback } from "react";
// import { Container, Modal, Button, Form } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
// import Swal from "sweetalert2";
// import axios from "axios";
// import Header_packages from "../components/Header_packages";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "./FeaturePackage.css";

// const FeaturePackagePage = () => {
//   const navigate = useNavigate();
//   const [isTermsAccepted, setIsTermsAccepted] = useState(true);
//   const [isRecurring, setIsRecurring] = useState(true);
//   const [showModal, setShowModal] = useState(false);

//   const handleTermsChange = (e) => {
//     setIsTermsAccepted(e.target.checked);
//   };

//   const handleRecurringChange = (e) => {
//     setIsRecurring(e.target.checked);
//   };

//   const formatDate = (dateString) => {
//     const options = { day: "2-digit", month: "short", year: "numeric" };

//     const date = new Date(dateString);
//     return date.toLocaleDateString("en-GB", options);
//   };

//   const handleSubscribe = async () => {
//     try {
//       const user = JSON.parse(sessionStorage.getItem("userData")) || {};
//       const sessionUserId = user.applicationUserId;

//       const response = await axios.post(
//         "https://bundleapi.webddocsystems.com/api/Account/ActivateFeatureSusbcription",
//         null,
//         {
//           params: {
//             patientId: sessionUserId,
//             productid: 1,
//           },
//         }
//       );

//       if (response.status === 200 && response.data.responseCode === "0000") {
//         const currentDate = new Date();
//         const expiryDate = new Date();
//         expiryDate.setDate(expiryDate.getDate() + 1);
//         user.featuresPackage = true;
//         user.featurePackageDetails.activeDate = formatDate(
//           currentDate.toISOString()
//         );
//         user.featurePackageDetails.nextChargeDare = formatDate(
//           expiryDate.toISOString()
//         );
//         sessionStorage.setItem("userData", JSON.stringify(user));

//         Swal.fire({
//           title: "Subscribed!",
//           text: "You have successfully subscribed to the Features Package.",
//           icon: "success",
//           confirmButtonText: "OK",
//           allowOutsideClick: false,
//         }).then(() => {
//           // navigate(`/?userMsisdn=${user.userMsisdn}`);
//           navigate(`/dashboard`);
//           //   window.location.reload();
//         });
//       } else {
//         Swal.fire({
//           title: "Faild!",
//           text: "Something went wront please try again later.",
//           icon: "faild",
//           confirmButtonText: "OK",
//           allowOutsideClick: false,
//         }).then(() => {});
//       }
//     } catch (error) {
//       console.error("Error activating subscription:", error);
//     }
//   };

//   const handleShowModal = () => setShowModal(true);
//   const handleCloseModal = () => setShowModal(false);

//   return (
//     <Container fluid>
//       <Header_packages title="Package" />
//       <div style={{ paddingTop: "100px" }}></div>
//       <div className="card p-4 shadow-lg rounded-lg">
//         <h3 className="card-title text-center mb-4">
//           Avail This Service for 5 AFN/Daily
//         </h3>
//         <p className="card-text text-left mb-4">
//           Explore premium features for just 5 Afghani per day.
//         </p>
//         <div className="text-left mb-4">
//           <p className="card-text">✔ See previous medical history.</p>
//           <p className="card-text">
//             ✔ View expert doctor profiles and consult.
//           </p>
//           <p className="card-text">✔ Add vitals and get filtered reports.</p>
//           <p className="card-text">✔ Get health tips.</p>
//         </div>
//         <Form>
//           <Form.Group className="mb-3">
//             <Form.Check
//               type="checkbox"
//               label="Recurring Payments"
//               checked={isRecurring}
//               onChange={handleRecurringChange}
//             />
//           </Form.Group>

//           <Form.Group className="mb-3">
//             <Form.Check
//               type="checkbox"
//               label={
//                 <span>
//                   I agree to the{" "}
//                   <a
//                     href="#"
//                     onClick={handleShowModal}
//                     className="text-primary"
//                   >
//                     terms and conditions
//                   </a>
//                 </span>
//               }
//               checked={isTermsAccepted}
//               onChange={handleTermsChange}
//             />
//           </Form.Group>

//           {/* <Button
//             variant="primary"
//             disabled={!isTermsAccepted}
//             onClick={() => alert("Package purchased!")}
//             className="w-100"
//           >
//             Pay Now
//           </Button> */}
//           <Button
//             variant="outline-info"
//             style={{
//               fontWeight: "500",
//               color: "#ffffff",
//               backgroundColor: "#719E19",
//               borderRadius: "40px",
//               border: 0,
//               boxShadow: "0 0 6.3px 1px rgba(0, 0, 0, 0.25)",
//             }}
//             disabled={!isTermsAccepted}
//             onClick={handleSubscribe}
//             className="w-100"
//           >
//             Subscribe
//           </Button>
//         </Form>
//       </div>

//       {/* Terms and Conditions Modal */}
//       <Modal show={showModal} onHide={handleCloseModal} centered>
//         <Modal.Header closeButton>
//           <Modal.Title>Terms and Conditions</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <p>
//             <strong>POLICY TERMS & EXCLUSIONS</strong>
//           </p>
//           <ul>
//             <li>
//               <p>
//                 All plans are auto-renewed for the next 5 years or till
//                 attainment of maximum covered age of the customer, whichever
//                 comes earlier.
//               </p>
//             </li>
//             <li>
//               <p>Pre-existing conditions are not covered.</p>
//             </li>
//             <li>
//               <p>Cosmetic or surgical procedures are not covered.</p>
//             </li>
//             <li>
//               <p>
//                 Regular medicine & SI (Surgical Instruments) do not fall under
//                 IPD.
//               </p>
//             </li>
//             <li>
//               <p>
//                 MMC (Major Medical Care) & DD (Dread Disease) cover will apply
//                 under IPD admission cover only.
//               </p>
//             </li>
//             <li>
//               <p>
//                 Cover restricts to IPD; Patient must be admitted for at least 24
//                 hours on bed to avail cover.
//               </p>
//             </li>
//             <li>
//               <p>
//                 Daycare surgeries or any admission less than 24 hours, including
//                 emergencies, are not covered.
//               </p>
//             </li>
//             <li>
//               <p>No Maternity Cover applies.</p>
//             </li>
//             <li>
//               <p>
//                 Policy can be reversed within 15 days after subscription only
//                 for 6-month and annual plans.
//               </p>
//             </li>
//           </ul>
//           <p>
//             <strong>DOCTORS CONSULTANCY & TIMING:</strong>
//           </p>
//           <p>
//             Doctors' services are available from Monday – Saturday, 9:00 am –
//             9:00 pm.
//           </p>
//           <p>
//             <strong>CLAIMS PAYMENT:</strong>
//           </p>
//           <ul>
//             <li>
//               <p>
//                 Claim payment TAT is 15 working days after complete document
//                 submission.
//               </p>
//             </li>
//           </ul>
//           <p>
//             <strong>REQUIRED DOCUMENTS:</strong>
//           </p>
//           <ol>
//             <li>
//               <p>CNIC Copy (Both sides)</p>
//             </li>
//             <li>
//               <p>Treatment details/Doctor’s prescription</p>
//             </li>
//             <li>
//               <p>Admission & discharge slip</p>
//             </li>
//             <li>
//               <p>
//                 Payment receipt/bills (payment paid by patient/customer in
//                 hospital)
//               </p>
//             </li>
//             <li>
//               <p>Lab reports, if any</p>
//             </li>
//             <li>
//               <p>MSISDN & Easypaisa account</p>
//             </li>
//           </ol>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleCloseModal}>
//             Close
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </Container>
//   );
// };

// export default FeaturePackagePage;
