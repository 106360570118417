import React, { useEffect, useState } from "react";
import { useLoading } from "../contexts/LoadingContext";
import PackageCard from "../components/PackageCard";
import Header from "../components/Header";
import Doctors_db from "../components/Doctors_db";
import HealthTips from "../components/HealthTips";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const Main = () => {
  const { isLoading, setIsLoading } = useLoading();
  const [isUserData, setUserData] = useState(null);
  const { t } = useTranslation();

  // Function to simulate the gtag function
  const gtag = (action, params) => {
    console.log(`gtag called with action: ${action}`, params); // Console log to verify gtag call
    window.dataLayer.push({ event: action, ...params });
  };

  // Track page view on component mount
  useEffect(() => {
    console.log("Page load detected, tracking page view...");
    gtag("config", { page_path: "/dashboard" });
  }, []);

  // Load user data from session storage
  useEffect(() => {
    const sessionUser = JSON.parse(sessionStorage.getItem("userData"));
    setUserData(sessionUser);
    console.log("User data loaded from session:", sessionUser); // Console log to verify user data
  }, []);

  // Alert logic with SweetAlert for redirected users
  useEffect(() => {
    const sessionUser = JSON.parse(sessionStorage.getItem("userData"));
    const redirectFromVideoCall = sessionStorage.getItem("redirectFromVideoCall");

    if (redirectFromVideoCall === "true") {
      console.log("Redirect from video call detected, showing alert...");
      Swal.fire({
        icon: "info",
        title: t("consultationAlertTitle"), 
        text: t("consultationAlertText"), 
        confirmButtonText: t("consultationAlertButton"),
      }).then(() => {
        console.log("Alert dismissed, updating session data...");
        sessionUser.package = "";
        sessionStorage.setItem("userData", JSON.stringify(sessionUser));
        sessionStorage.removeItem("redirectFromVideoCall");

        // Track SweetAlert click event
        gtag("event", { 
          event_category: "Alert", 
          event_label: "Consultation Alert Dismissed" 
        });
      });
    }
  }, [t]);

  // Track PackageCard click
  const handlePackageCardClick = () => {
    console.log("Package card clicked, tracking event...");
    gtag("event", { 
      event_category: "Package", 
      event_label: "Health Insurance Click" 
    });
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Header userSession={isUserData} />
      <div className="container mt-2">
        <div className="row mb-3">
          <div className="col-12">
            <PackageCard
              userData={isUserData}
              icon="assets/NewMiniapp/health-insurance.png"
              label={t("healthInsuranceLabel")}
              onClick={handlePackageCardClick} // Attach click handler for analytics
            />
          </div>
        </div>
        <hr className="bold-hr" />
        <Doctors_db />
        <hr className="bold-hr" />
        <HealthTips />
      </div>
    </>
  );
};

export default Main;
